<template>
  <div>
    <div class="contain">
      <div class="information">
        <!-- 步骤条 -->
        <div class="steps">
          <div
            :class="['stepsList', index == step ? 'stepsClick' : '']"
            v-for="(item, index) in setData"
            :key="index"
          >
            <div v-if="step > index" class="icon">
              <i class="el-icon-check"></i>
            </div>
            <div v-else :class="['number', index == step ? 'numberClick' : '']">
              {{ index + 1 }}
            </div>

            <div class="text">{{ item.text }}</div>
          </div>
        </div>
        <!-- 个人信息 -->
        <div v-if="step == 0">
          <div class="content">
            <div class="title">个人信息</div>
            <div class="flex1">
              <div class="line">
                <div class="name">姓名</div>
                <input type="text" placeholder="请输入" />
              </div>
              <div class="line">
                <div class="name">性别</div>
                <div class="radio">
                  <div
                    :class="['sex', ISsex ? 'sexC' : '']"
                    @click="ISsex = true"
                  >
                    男
                  </div>
                  <div
                    :class="['sex', ISsex ? '' : 'sexC']"
                    @click="ISsex = false"
                  >
                    女
                  </div>
                </div>
              </div>
              <div class="line">
                <div class="name">出生日期</div>
                <input type="text" placeholder="请选择" />
                <i class="el-icon-date"></i>
              </div>
              <div class="line">
                <div class="name">你的身份</div>
                <div class="radio">
                  <div
                    :class="['identity', ISidentity ? 'identityC' : '']"
                    @click="ISidentity = true"
                  >
                    职场人
                  </div>
                  <div
                    :class="['identity', ISidentity ? '' : 'identityC']"
                    @click="ISidentity = false"
                  >
                    在校生
                  </div>
                </div>
              </div>
              <div class="line">
                <div class="name">开始工作年份</div>
                <input type="text" placeholder="请选择" />
                <i class="el-icon-date"></i>
              </div>
              <div class="line">
                <div class="name">居住地</div>
                <input type="text" placeholder="请选择" />
                <i class="el-icon-arrow-down"></i>
              </div>
              <div class="line">
                <div class="name">邮箱</div>
                <input type="text" placeholder="请输入" />
              </div>
              <div class="line">
                <div class="name">个人标签</div>
                <input type="text" placeholder="请选择" />
                <i class="el-icon-menu"></i>
              </div>
            </div>
            <!-- 按钮 -->
            <div class="btn">
              <div
                class="back"
                v-if="step == 1 || step == 2 || step == 3"
                @click="back"
              >
                上一步
              </div>
              <div class="next" @click="next">下一步</div>
            </div>
          </div>
        </div>
        <!-- 求职意向 -->
        <div v-if="step == 1">
          <div class="content">
            <div class="title">求职意向</div>
            <div class="intention">
              <div class="list">
                <div class="listname">期望薪资</div>
                <input
                  type="text"
                  placeholder="月薪"
                  style="width: 80px; margin-right: 10px"
                />
                <input type="text" placeholder="请选择" style="width: 245px" />
                <i class="el-icon-arrow-down"></i>
              </div>
              <div class="list">
                <div class="listname">职位</div>
                <input type="text" placeholder="请选择" />
                <i class="el-icon-menu"></i>
              </div>
              <div class="list">
                <div class="listname">技能</div>
                <input type="text" placeholder="请选择（选填）" />
                <i class="el-icon-menu"></i>
              </div>
              <div class="list">
                <div class="listname">行业</div>
                <input type="text" placeholder="请选择（选填）" />
                <i class="el-icon-menu"></i>
              </div>
            </div>
            <!-- 按钮 -->
            <div class="btn">
              <div
                class="back"
                v-if="step == 1 || step == 2 || step == 3"
                @click="back"
              >
                上一步
              </div>
              <div class="next" @click="next">下一步</div>
            </div>
          </div>
        </div>
        <!-- 教育经历 -->
        <div v-if="step == 2">
          <div class="content">
            <div class="title">教育经历</div>
            <div class="intention">
              <div class="list">
                <div class="listname">学校</div>
                <input type="text" placeholder="请输入" />
              </div>
              <div class="list">
                <div class="listname">时间</div>
                <input
                  type="text"
                  placeholder="请选择"
                  style="width: 155px; margin-right: 10px"
                />
                <b>-</b>
                <input
                  type="text"
                  placeholder="请选择"
                  style="width: 155px; margin-left: 10px"
                />
                <i class="el-icon-date"></i>
              </div>
              <div class="list">
                <div class="listname">学位/学历</div>
                <input type="text" placeholder="最高学历" />
                <i class="el-icon-arrow-down"></i>
              </div>
              <div class="list">
                <div class="listname">专业</div>
                <input type="text" placeholder="请选择" />
                <i class="el-icon-menu"></i>
                <div class="eduAdd">
                  <i class="el-icon-circle-plus-outline"></i
                  >保存并继续添加教育经验
                </div>
              </div>
            </div>
            <!-- 按钮 -->
            <div class="btn">
              <div
                class="back"
                v-if="step == 1 || step == 2 || step == 3"
                @click="back"
              >
                上一步
              </div>
              <div class="next" @click="next">下一步</div>
            </div>
          </div>
        </div>
        <!-- 工作经验 -->
        <div v-if="step == 3">
          <div class="content">
            <div class="title">工作经验</div>
            <div class="flex1">
              <div class="line">
                <div class="name">公司</div>
                <input type="text" placeholder="请输入" />
              </div>
              <div class="line">
                <div class="name">时间</div>
                <input
                  type="text"
                  placeholder="请选择"
                  style="width: 110px; margin-right: 10px"
                />
                <b>-</b>
                <input
                  type="text"
                  placeholder="请选择"
                  style="width: 110px; margin-left: 10px"
                />
                <i class="el-icon-date"></i>
              </div>
              <div class="line">
                <div class="name">职能</div>
                <input type="text" placeholder="请填写/选择" />
                <i class="el-icon-date"></i>
              </div>
              <div class="line">
                <div class="name">行业</div>
                <input type="text" placeholder="请填写/选择" />
                <i class="el-icon-menu"></i>
              </div>
              <div class="line">
                <div class="name">职位</div>
                <input type="text" placeholder="请填写" />
              </div>
              <div class="line">
                <div class="name">部门</div>
                <input type="text" placeholder="请填写（选填）" />
              </div>
              <div class="line" style="width: 100%">
                <div class="name">工作描述</div>
                <textarea></textarea>
              </div>
            </div>
            <!-- 按钮 -->
            <div class="btn">
              <div
                class="back"
                v-if="step == 1 || step == 2 || step == 3"
                @click="back"
              >
                上一步
              </div>
              <div class="finish" @click="goto('/finish')">创建完成</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      step: 0,
      ISsex: true,
      ISidentity: true,
      setData: [
        {
          text: "个人信息",
        },
        {
          text: "求职意向",
        },
        {
          text: "教育经历",
        },
        {
          text: "工作经验",
        },
      ],
    };
  },
  methods: {
    goto(url) {
      this.$router.push(url);
    },
    next() {
      if (this.step++ > 2) this.step = 0;
    },
    back() {
      if (this.step-- == 0) this.step = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.contain {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .information {
    width: 1000px;
    height: 560px;
    background: white;
    border-radius: 4px;
    padding: 25px 35px 45px;
    box-sizing: border-box;
  position: relative;
  }
}
// 步骤条
.steps {
  display: flex;
  align-items: center;
  margin-bottom: 65px;
  .stepsList {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
    margin-right: 15px;
    .number {
      width: 25px;
      height: 25px;
      border-radius: 4px;
      line-height: 25px;
      text-align: center;
      background: #ececec;
      margin-right: 15px;
    }
    .numberClick {
      border: 1px solid #276bf2;
      color: #276bf2;
      box-sizing: border-box;
      background: #fff;
    }
    .icon {
      width: 25px;
      height: 25px;
      text-align: center;
      line-height: 25px;
      margin-right: 15px;
      background-color: rgba(39, 107, 242, 0.1);
      color: #276bf2;
    }
  }
}
// 内容
.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  font-size: 14px;
  color: #333;
  .title {
    width: 64px;
    color: #276bf2;
    font-size: 16px;
    border-bottom: 4px solid #276bf2;
    padding-bottom: 15px;
    margin-bottom: 35px;
  }
  .line {
    width: 50%;
    display: flex;
    line-height: 40px;
    position: relative;
    margin-bottom: 15px;
    .radio {
      display: flex;
      font-size: 14px;
      .sex,
      .identity {
        width: 116px;
        margin-right: 13px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #ececec;
        box-sizing: border-box;
        border-radius: 4px;
      }
      .sex:hover,
      .identity:hover {
        cursor: pointer;
      }
      .sexC,
      .identityC {
        border: none;
        color: #276bf2;
        background: rgba(39, 107, 242, 0.1);
      }
    }
    .name {
      width: 85px;
      text-align: right;
      margin-right: 20px;
    }
    input {
      width: 245px;
      border: 1px solid #ececec;
      box-sizing: border-box;
      border-radius: 4px;
      text-indent: 1em;
    }
    textarea{
      width: 690px;
      border: 1px solid #ececec;
      box-sizing: border-box;
      padding: 0;
      border-radius: 4px;
      text-indent: 1em;
    }
    i {
      position: absolute;
      right: 105px;
      line-height: 40px;
      color: #276bf2;
    }
    .el-icon-arrow-down {
      color: #999;
    }
  }
  .intention {
    padding: 0 225px;
    .list {
      display: flex;
      line-height: 40px;
      position: relative;
      margin-bottom: 15px;
      .listname {
        width: 85px;
        text-align: right;
        margin-right: 20px;
      }
      input {
        width: 335px;
        border: 1px solid #ececec;
        box-sizing: border-box;
        border-radius: 4px;
        text-indent: 1em;
      }
      i {
        position: absolute;
        right: 10px;
        line-height: 40px;
        color: #276bf2;
      }
      .el-icon-arrow-down {
        color: #999;
      }
      .eduAdd {
        position: absolute;
        color: #276bf2;
        right: 0;
        bottom: -35px;
        .el-icon-circle-plus-outline {
          position: relative;
        }
      }
    }
  }
}
// 按钮
.btn {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 45px;
  height: 45px;
  text-align: center;
  bottom: 35px;
  left: 0;
  .next {
    width: 200px;
    background: #276bf2;
    color: white;
    border-radius: 4px;
  }
  .back {
    width: 200px;
    border: 1px solid #276bf2;
    box-sizing: border-box;
    color: #276bf2;
    margin-right: 10px;
    border-radius: 4px;
  }
  .finish {
    width: 200px;
    background: #276bf2;
    color: white;
    border-radius: 4px;
  }
}
</style>